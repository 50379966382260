import Mgr from "@/core/services/security.service";

let mgr = new Mgr();

const orangeReport = {
  createRoles: [
    "Regional Director",
    "Regional Manager",
    "Crew Leader",
    "Executive",
    "Executive Team",
    "Branch Manager",
    "Account Manager"
  ],
  editRoles: [
    "Regional Director",
    "Regional Manager",
    "Crew Leader",
    "Executive",
    "Executive Team",
    "Branch Manager",
    "Account Manager"
  ],
  approveRoles: [
    "Regional Director",
    "Regional Manager",
    "Executive",
    "Executive Team",
    "Branch Manager",
    "Account Manager"
  ],
  viewRoles: [
    "Regional Director",
    "Regional Manager",
    "Executive",
    "Executive Team",
    "Branch Manager",
    "Account Manager",
    "Crew Leader",
    "Property Manager",
    "Board Member",
    "Resident",
    "Tenant/Resident",
    "Tenant/Resident - Neighborhood",
    "Landscape Committee"
  ]
};

const ninetyDayPlan = {
  createRoles: [
    "Regional Director",
    "Regional Manager",
    "Executive",
    "Executive Team",
    "Branch Manager",
    "Account Manager"
  ],
  editRoles: [
    "Regional Director",
    "Regional Manager",
    "Executive",
    "Executive Team",
    "Branch Manager",
    "Account Manager"
  ],
  viewRoles: [
    "Regional Director",
    "Regional Manager",
    "Executive",
    "Executive Team",
    "Branch Manager",
    "Account Manager",
    "Crew Leader",
    "Property Manager",
    "Board Member",
    "Resident",
    "Tenant/Resident",
    "Tenant/Resident - Neighborhood",
    "Landscape Committee"
  ]
};

const quarterlyPlanTemplate = {
  createRoles: [
    "Regional Director",
    "Regional Manager",
    "Executive",
    "Executive Team",
    "Branch Manager"
  ],
  editRoles: [
    "Regional Director",
    "Regional Manager",
    "Executive",
    "Executive Team",
    "Branch Manager"
  ],
  viewRoles: [
    "Regional Director",
    "Regional Manager",
    "Executive",
    "Executive Team",
    "Branch Manager",
    "Account Manager",
    "Crew Leader",
    "Property Manager",
    "Board Member",
    "Resident",
    "Tenant/Resident",
    "Tenant/Resident - Neighborhood",
    "Landscape Committee"
  ]
};

const invoice = {
  createRoles: [
    "Regional Director",
    "Regional Manager",
    "Executive",
    "Executive Team",
    "Branch Manager",
    "Account Manager",
    "Property Manager"
  ],
  editRoles: [
    "Regional Director",
    "Regional Manager",
    "Executive",
    "Executive Team",
    "Branch Manager",
    "Account Manager",
    "Property Manager"
  ],
  viewRoles: [
    "Regional Director",
    "Regional Manager",
    "Executive",
    "Executive Team",
    "Branch Manager",
    "Account Manager",
    "Crew Leader",
    "Property Manager",
    "Board Member",
    "Landscape Committee"
  ]
};

const proposal = {
  createRoles: [
    "Regional Director",
    "Regional Manager",
    "Executive",
    "Executive Team",
    "Branch Manager",
    "Account Manager",
    "Property Manager"
  ],
  editRoles: [
    "Regional Director",
    "Regional Manager",
    "Executive",
    "Executive Team",
    "Branch Manager",
    "Account Manager",
    "Property Manager"
  ],
  viewRoles: [
    "Regional Director",
    "Regional Manager",
    "Executive",
    "Executive Team",
    "Branch Manager",
    "Account Manager",
    "Crew Leader",
    "Property Manager",
    "Board Member",
    "Landscape Committee"
  ]
};

const serviceRequest = {
  filterByBranchRoles: [
    "Regional Director",
    "Regional Manager",
    "Executive",
    "Executive Team",
    "Branch Manager"
  ],
  filterByAccountManagerRoles: [
    "Regional Director",
    "Regional Manager",
    "Executive",
    "Executive Team",
    "Branch Manager"
  ],
  filterByCustomerRoles: [
    "Regional Director",
    "Regional Manager",
    "Crew Leader",
    "Executive",
    "Executive Team",
    "Branch Manager",
    "Account Manager"
  ],
  viewRoles: [
    "Regional Director",
    "Regional Manager",
    "Crew Leader",
    "Executive",
    "Executive Team",
    "Branch Manager",
    "Account Manager",
    "Property Manager",
    "Board Member",
    "Resident",
    "Tenant/Resident",
    "Tenant/Resident - Neighborhood",
    "Landscape Committee"
  ],
  createRoles: [
    "Regional Director",
    "Regional Manager",
    "Crew Leader",
    "Executive",
    "Executive Team",
    "Branch Manager",
    "Account Manager",
    "Property Manager",
    "Board Member",
    "Resident",
    "Tenant/Resident",
    "Tenant/Resident - Neighborhood",
    "Landscape Committee"
  ],
  editRoles: [
    "Regional Director",
    "Regional Manager",
    "Crew Leader",
    "Executive",
    "Executive Team",
    "Branch Manager",
    "Account Manager",
    "Property Manager",
    "Resident",
    "Tenant/Resident",
    "Tenant/Resident - Neighborhood",
    "Landscape Committee"
  ],
  deleteRoles: [
    "Regional Director",
    "Regional Manager",
    "Crew Leader",
    "Executive",
    "Executive Team",
    "Branch Manager",
    "Account Manager",
    "Property Manager"
  ],
  useOnBehalfOfRoles: ["Branch Manager", "Account Manager", "Property Manager"]
};

const message = {
  sendByBranchRoles: [
    "Regional Director",
    "Regional Manager",
    "Executive",
    "Executive Team",
    "Branch Manager"
  ],
  sendMyTeamRoles: [
    "Account Manager",
    "Regional Director",
    "Regional Manager",
    "Executive",
    "Executive Team",
    "Branch Manager"
  ],
  viewRoles: [
    "Regional Director",
    "Regional Manager",
    "Crew Leader",
    "Executive",
    "Executive Team",
    "Branch Manager",
    "Account Manager",
    "Property Manager",
    "Board Member",
    "Resident",
    "Tenant/Resident",
    "Tenant/Resident - Neighborhood",
    "Landscape Committee"
  ]
};

const manageUsers = {
  addRegularUserRoles: [
    "Regional Director",
    "Regional Manager",
    "Executive",
    "Executive Team",
    "Branch Manager",
    "Account Manager",
    "Crew Leader",
    "Property Manager"
  ],
  addYnMemberRoles: [
    "Regional Director",
    "Regional Manager",
    "Executive",
    "Executive Team",
    "Branch Manager",
    "Account Manager",
    "Crew Leader",
    "Property Manager"
  ],
  addAnyUserRoles: [
    "Regional Director",
    "Regional Manager",
    "Executive",
    "Executive Team",
    "Branch Manager",
    "Account Manager",
    "Crew Leader",
    "Property Manager"
  ],
  changePasswordRoles: [
    "Regional Director",
    "Regional Manager",
    "Executive",
    "Executive Team",
    "Branch Manager",
    "Account Manager",
    "Crew Leader",
    "Property Manager"
  ],
  hasAccessRoles: [
    "Regional Director",
    "Regional Manager",
    "Executive",
    "Executive Team",
    "Branch Manager",
    "Account Manager",
    "Crew Leader",
    "Property Manager"
  ],
  viewRoles: [
    "Regional Director",
    "Regional Manager",
    "Crew Leader",
    "Executive",
    "Executive Team",
    "Branch Manager",
    "Account Manager",
    "Property Manager",
    "Board Member",
    "Landscape Committee"
  ]
};

const ynDirectory = {
  editRoles: [
    "Regional Director",
    "Regional Manager",
    "Executive",
    "Executive Team",
    "Branch Manager"
  ],
  viewRoles: [
    "Regional Director",
    "Regional Manager",
    "Executive",
    "Executive Team",
    "Branch Manager",
    "Account Manager",
    "Crew Leader",
    "Property Manager",
    "Board Member",
    "Landscape Committee"
  ]
};

const communitySentiment = {
  editRoles: ["Property Manager"],
  viewRoles: [
    "Regional Director",
    "Regional Manager",
    "Executive",
    "Executive Team",
    "Branch Manager",
    "Account Manager",
    "Crew Leader",
    "Property Manager",
    "Board Member",
    "Tenant/Resident",
    "Tenant/Resident - Neighborhood",
    "Landscape Committee"
  ]
};

const documents = {
  viewRoles: [
    "Regional Director",
    "Regional Manager",
    "Executive",
    "Executive Team",
    "Branch Manager",
    "Account Manager",
    "Crew Leader",
    "Property Manager",
    "Board Member",
    "Landscape Committee",
    "Tenant/Resident",
    "Tenant/Resident - Neighborhood"
  ],
  uploadRoles: [
    "Regional Director",
    "Regional Manager",
    "Executive",
    "Executive Team",
    "Branch Manager",
    "Account Manager",
    "Crew Leader",
    "Property Manager"
  ]
};

const fileLibrary = {
  createFolderRoles: [
    "Regional Director",
    "Regional Manager",
    "Executive",
    "Executive Team",
    "Branch Manager"
  ],
  deleteFolderRoles: ["Executive", "Executive Team"],
  deleteFileRoles: [
    "Regional Director",
    "Regional Manager",
    "Executive",
    "Executive Team",
    "Branch Manager",
    "Account Manager"
  ]
};

const settings = {
  editCommunityLogoRoles: [
    "Regional Director",
    "Regional Manager",
    "Executive",
    "Executive Team",
    "Branch Manager",
    "Account Manager",
    "Crew Leader",
    "Property Manager"
  ],
  editPasswordRoles: [
    "Regional Director",
    "Regional Manager",
    "Executive",
    "Executive Team",
    "Branch Manager",
    "Account Manager",
    "Crew Leader",
    "Property Manager",
    "Board Member",
    "Tenant/Resident",
    "Landscape Committee"
  ],
  editNotificationsRoles: [
    "Regional Director",
    "Regional Manager",
    "Executive",
    "Executive Team",
    "Branch Manager",
    "Account Manager",
    "Crew Leader",
    "Property Manager",
    "Board Member",
    "Tenant/Resident",
    "Landscape Committee"
  ]
};

function hasAccess(role, allowForRoles) {
  if (role == null || !allowForRoles) {
    return false;
  } else {
    let roles = role.split(",");
    for (let i = 0; i < roles.length; i++) {
      return allowForRoles.indexOf(roles[i]) > -1;
    }
  }
}

export default {
  async getPermissions() {
    return await mgr
      .getRole()
      .then(function(role) {
        let permissions = {
          orangeReport: {
            canCreate: hasAccess(role, orangeReport.createRoles),
            canEdit: hasAccess(role, orangeReport.editRoles),
            canApprove: hasAccess(role, orangeReport.approveRoles),
            canView: hasAccess(role, orangeReport.viewRoles)
          },
          ninetyDayPlan: {
            canCreate: hasAccess(role, ninetyDayPlan.createRoles),
            canEdit: hasAccess(role, ninetyDayPlan.editRoles),
            canView: hasAccess(role, ninetyDayPlan.viewRoles)
          },
          quarterlyPlanTemplate: {
            canCreate: hasAccess(role, quarterlyPlanTemplate.createRoles),
            canEdit: hasAccess(role, quarterlyPlanTemplate.editRoles),
            canView: hasAccess(role, quarterlyPlanTemplate.viewRoles)
          },
          invoice: {
            canCreate: hasAccess(role, invoice.createRoles),
            canEdit: hasAccess(role, invoice.editRoles),
            canView: hasAccess(role, invoice.viewRoles)
          },
          proposal: {
            canCreate: hasAccess(role, proposal.createRoles),
            canEdit: hasAccess(role, proposal.editRoles),
            canView: hasAccess(role, proposal.viewRoles)
          },
          serviceRequest: {
            canFilterByBranch: hasAccess(
              role,
              serviceRequest.filterByBranchRoles
            ),
            canFilterByAccountManager: hasAccess(
              role,
              serviceRequest.filterByAccountManagerRoles
            ),
            canFilterByCustomer: hasAccess(
              role,
              serviceRequest.filterByCustomerRoles
            ),
            canView: hasAccess(role, serviceRequest.viewRoles),
            canCreate: hasAccess(role, serviceRequest.createRoles),
            canEdit: hasAccess(role, serviceRequest.editRoles),
            canDelete: hasAccess(role, serviceRequest.deleteRoles),
            useOnBehalfOf: hasAccess(role, serviceRequest.useOnBehalfOfRoles)
          },
          message: {
            canSendByBrach: hasAccess(role, message.sendByBranchRoles),
            canSendMyTeam: hasAccess(role, message.sendMyTeamRoles),
            canView: hasAccess(role, message.viewRoles)
          },
          manageUsers: {
            canAddUser: hasAccess(role, manageUsers.addRegularUserRoles),
            canAddYnMember: hasAccess(role, manageUsers.addYnMemberRoles),
            canAddAnyUser: hasAccess(role, manageUsers.addAnyUserRoles),
            canChangePassword: hasAccess(role, manageUsers.changePasswordRoles),
            canAccess: hasAccess(role, manageUsers.hasAccessRoles),
            canView: hasAccess(role, manageUsers.viewRoles)
          },
          ynDirectory: {
            canEdit: hasAccess(role, ynDirectory.editRoles),
            canView: hasAccess(role, ynDirectory.viewRoles)
          },
          communitySentiment: {
            canEdit: hasAccess(role, communitySentiment.editRoles),
            canView: hasAccess(role, communitySentiment.viewRoles)
          },
          documents: {
            canView: hasAccess(role, documents.viewRoles),
            canUpload: hasAccess(role, documents.uploadRoles)
          },
          fileLibrary: {
            canCreateFolder: hasAccess(role, fileLibrary.createFolderRoles),
            canDeleteFolder: hasAccess(role, fileLibrary.deleteFolderRoles),
            canDeleteFile: hasAccess(role, fileLibrary.deleteFileRoles)
          },
          settings: {
            canEditCommunityLogo: hasAccess(
              role,
              settings.editCommunityLogoRoles
            ),
            canEditPassword: hasAccess(role, settings.editPasswordRoles),
            canEditNotifications: hasAccess(
              role,
              settings.editNotificationsRoles
            )
          }
        };
        return Promise.resolve(permissions);
      })
      .catch(function() {
        let permissions = {
          orangeReport: {
            canCreate: false,
            canEdit: false,
            canApprove: false,
            canView: false
          },
          ninetyDayPlan: {
            canCreate: false,
            canEdit: false,
            canView: false
          },
          quarterlyPlanTemplate: {
            canCreate: false,
            canEdit: false,
            canView: false
          },
          invoice: {
            canCreate: false,
            canEdit: false,
            canView: false
          },
          proposal: {
            canCreate: false,
            canEdit: false,
            canView: false
          },
          serviceRequest: {
            canFilterByBranch: false,
            canFilterByAccountManager: false,
            canFilterByCustomer: false,
            canView: false,
            canCreate: false,
            canEdit: false,
            canDelete: false,
            useOnBehalfOf: false
          },
          message: {
            canSendByBranch: false,
            canSendMyTeam: false,
            canView: false
          },
          manageUsers: {
            canAddUser: false,
            canAddYnMember: false,
            canAddAnyUser: false,
            canChangePassword: false,
            canAccess: false,
            canView: false
          },
          ynDirectory: {
            canEdit: false,
            canView: false
          },
          communitySentiment: {
            canEdit: false,
            canView: false
          },
          documents: {
            canEdit: false,
            canUpload: false,
            canView: false
          },
          fileLibrary: {
            canCreateFolder: false,
            canDeleteFolder: false
          }
        };

        return Promise.resolve(permissions);
      });
  }
};
