export default {
  formatName(firstName, lastName) {
    if (firstName && lastName && firstName.length > 0 && lastName.length > 0) {
      return `${firstName} ${lastName.substring(0, 1)}`;
    }
    return "";
  },
  initials(firstName, lastName) {
    if (firstName && lastName && firstName.length > 0 && lastName.length > 0) {
      return `${firstName.substring(0, 1)} ${lastName.substring(0, 1)}`;
    }
    return "";
  },
  capitalizeFirstLetter(string) {
    if (string && string.length > 0) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    return "";
  }
};
