export default {
  formatTableDate(date) {
    if (date) {
      let dateToFormat = new Date(Date.parse(date));

      return `${dateToFormat.getUTCMonth() +
        1}/${dateToFormat.getUTCDate()}/${dateToFormat.getUTCYear() - 100}`;
    }
    return null;
  },
  formatEditDate(date) {
    // if (date) {
    //   return `${new Date(date).getFullYear()}-${new Date(date).getMonth() +
    //     1}-${new Date(date).getDate()}`;
    // }
    return this.formatDateUs(date);
    // return null;
  },
  convertToMilliseconds(date) {
    if (date) {
      let dateToConvert = new Date(Date.parse(date));

      const removeDetailedTimestamp = `${dateToConvert.getUTCFullYear()}-${dateToConvert.getUTCMonth() +
        1}-${dateToConvert.getUTCDate()}`;
      return new Date(Date.parse(removeDetailedTimestamp)).getUTCTime();
    }
    return null;
  },
  formatDateUs(date) {
    if (date) {
      let dateToFormat = new Date(Date.parse(date));
      return `${dateToFormat.getUTCMonth() +
        1}/${dateToFormat.getUTCDate()}/${dateToFormat.getUTCFullYear()}`;
    }
    return null;
  }
};
