import { GET_QUERY } from "@/core/store/crud.module";
import { API_CUSTOMERS } from "@/core/store/crud.module";
import store from "@/core/store";

// import axios from "axios";
// import router from "@/router";
// import store from "@/core/store";
// import JwtService from "@/core/services/jwt.service";

export default {
  getPopulatedComunityInfo(data) {
    let info = data;
    info.id = data.aspireId;
    if (!info.name) info.name = "Community " + data.aspireId;
    if (!info.recentOrangeReports) info.recentOrangeReports = [];

    if (!info.propertyManager?.role)
      info.propertyManager.role = "Property Manager";
    if (!info.propertyManager?.phone)
      info.propertyManager.phone = "- No Phone Number Specified -";
    if (!info.managementCompany.address?.line1)
      info.managementCompany.address.line1 = "";
    if (!info.serviceStatus)
      info.serviceStatus = {
        status: "On-Schedule"
      };

    if (!info.branchOfficeStaff)
      info.branchOfficeStaff = {
        branchOfficeCity: "Wimington",
        brancOfficeState: "NC",
        branchOfficeAddress: "",
        branchOfficeName: "Wimington, NC",
        branchManager: "Emma Thompson",
        accountManager: info.accountManager.fullName,
        accountManagerPhone: "555-555-5555",
        crewLeader: "Jaun Valdez",
        regionalDirector: "Fred G. Sandford",
        communityCity: info.address?.city,
        communityAddress: info.address?.line1,
        communityPostalCode: info.address?.postalCode,
        communityStateCode: info.address?.stateCode
      };

    info.orangeReports = data.orangeReports?.$values;
    info.invoices = data.invoices?.$values;
    info.proposals = data.proposals?.$values;
    info.serviceRequests = data.serviceRequests?.$values;
    info.ninetyDayPlans = data.ninetyDayPlans?.$values;
    info.serviceRequestsNumbers = { open: 0, inProcess: 0, closed: 0 };

    return info;
  },
  async getComunityInfo(customerId, communityInfo, snackbar) {
    // this.loading = true;

    // this.isLoading = true;

    // let info;
    // let items = await this.getCustomers();

    await store
      .dispatch(GET_QUERY, {
        listName: API_CUSTOMERS,
        id: customerId
      })
      .then(response => {
        if (response.status >= 200 || response.status <= 204) {
          communityInfo = response.data;
          if (!communityInfo.name) communityInfo.name = "Missing_Name";
          communityInfo.recentOrangeReports = [];
          communityInfo.serviceStatus = {};
        } else {
          snackbar.showMessage({
            content: response.data || "Something went wrong!",
            color: "red"
          });
        }

        // this.loading = false;
        // this.isLoading = false;
      });
    // for (let i = 0; i < items.length; i++) {
    //   if (this.customerId == items[i].id) {
    //     this.communityInfo = items[i];
    //     // info = items[i];
    //     break;

    //   }
    // }
    // items.forEach(element => {
    //   if (
    //     element.contact.name === this.communityInfo.contact.name &&
    //     element.name !== this.communityInfo.name
    //   )
    //     this.relatedCommunityItems.push(element);
    // });

    // return info;
  }
};
