<template>
  <div>
    <v-row>
      <v-col md="12">
        <v-card :img="headerBackground" elevation="0">
          <v-card-text>
            <router-link :to="getHomeUrl()">
              <div class="d-flex">
                <div>
                  <v-img
                    v-if="communityPhoto"
                    :src="communityPhoto"
                    max-height="150"
                    max-width="250"
                  ></v-img>
                  <h1 v-else class="white--text text-uppercase">
                    {{ communityName }}
                  </h1>
                  <div class="d-flex">
                    <div
                      class="weather-container temperature-container text-center white--text"
                    >
                      <v-progress-circular
                        v-if="loading"
                        :active="loading"
                        :indeterminate="loading"
                        color="primary"
                      ></v-progress-circular>
                      <span class="text-h6" v-else>
                        {{ Math.ceil(temperature) }} &deg;
                      </span>
                    </div>
                    <div
                      class="weather-container humidity-container text-center white--text"
                    >
                      <span class="text-h6"> {{ humidity }}% </span>
                    </div>
                  </div>
                </div>
                <v-spacer></v-spacer>
                <div class="header-settings-containter">
                  <p>
                    <router-link
                      class="link"
                      :to="
                        isInternalView
                          ? `/customers/${communityId}/settings`
                          : `/customerView/${communityId}/settings`
                      "
                    >
                      <v-btn class="white--text" small>
                        Settings
                        <v-icon class="ml-1" color="white" small>
                          mdi-settings-outline
                        </v-icon>
                      </v-btn>
                    </router-link>
                  </p>
                </div>
              </div>
            </router-link>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "CommunityHeader",
  data() {
    return {
      show: false,
      message: "",
      color: "",
      headerBackgroundUrl: ""
    };
  },
  props: {
    communityName: {
      type: String,
      default: ""
    },
    weatherCode: {
      default: 800
    },
    temperature: {
      type: Number,
      default: 0
    },
    humidity: {
      type: Number,
      default: 0
    },
    loading: {
      default: true
    },
    isInternalView: {
      default: true
    },
    isResidentView: {
      default: false
    },
    communityId: {
      default: 0
    },
    communityPhoto: {
      type: String,
      default: ""
    }
  },
  methods: {
    getHomeUrl() {
      if (this.isInternalView) return `/customers/${this.communityId}`;
      else {
        if (this.isResidentView)
          return `/customerView/${this.communityId}/requests`;
        return `/customerView/${this.communityId}`;
      }
    }
  },
  computed: {
    headerBackground() {
      if (this.loading) return "media/assets/comDashTitle/no-rain.jpg";
      if (this.weatherCode == 800 || this.weatherCode == 801)
        return "media/assets/comDashTitle/no-rain.jpg";
      if (this.weatherCode > 801) return "media/assets/comDashTitle/clouds.jpg";
      return "media/assets/comDashTitle/rain.jpg";
    },
    logoBackgroundStyle() {
      if (this.communityPhoto)
        return (
          "background-image: url('" +
          this.communityPhoto +
          "'); background-position: right center; background-size: contain;"
        );
      else return "";
    }
  }
};
</script>

<style lang="scss" scoped>
a.link.router-link-active button,
a.link.router-link-exact-active button,
a.link button:hover {
  background-color: #df6526 !important;
}

a.link button {
  background-color: #37bcdf !important;
}

h1 {
  // margin-top: 50px;
}
.weather-container {
  margin: 10px;
  margin-bottom: 0;
  padding: 20px;
  width: 100px;
  height: 100px;
  background-position: center center;
  background-repeat: none;
  background-size: contain;
}
.humidity-container {
  margin-top: 30px;

  padding-bottom: 0;
  background-image: url("/media/assets/comDashTitle/cloud.png");
}
.temperature-container {
  padding-top: 40px;
  background-image: url("/media/assets/comDashTitle/sun.png");
}

.header-settings-containter {
}

.header-settings-containter p {
  // background-color: rgba(255, 255, 255, 1);
  // color: black;
}
</style>
